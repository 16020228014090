import {
  MAIN_PLL_URL,
} from '../../../Config/League/league.config'
import { TEAM_LOGO_IMAGES } from '../../../Constants/team'
import { getFullTeamName, getGeneralTeamIc } from '../../../Utils'

export const getTeamIc = (teamName: string | undefined, year: number) => {
  if(teamName){
    if(teamName.length <= 4){ //Short team code
      teamName = getFullTeamName(teamName)
      if(!teamName) return getGeneralTeamIc()
    }
    const lowerCase = teamName.toLowerCase()
    if (year < 2024) {
      return `https://${MAIN_PLL_URL}/${lowerCase}_primary_fullcolor`
    }
    if (lowerCase in TEAM_LOGO_IMAGES) {
      return TEAM_LOGO_IMAGES[lowerCase]
    }
  }
  return getGeneralTeamIc()
}
