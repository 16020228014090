import { MAIN_PLL_URL, Team_LOGO_URL } from '../Config/League/league.config'

export const WLL_TEAM_CODES = [
  'WCHA',
  'WGUA',
  'WCHR',
  'WPLM'
]

export const teamStatsTypes = [
  'scoresPG',
  'saa',
  'shotsPG',
  'shotPct',
  'twoPointGoals',
  'assists',
  'faceoffPct',
  'savePct',
  'causedTurnovers',
  'powerPlayPct',
  'manDownPct',
  'totalPasses',
]
export const champSeriesTeamStatsTypes = [
  'scoresPG',
  'saa',
  'shotsPG',
  'shotPct',
  'twoPointGoals',
  'savePct',
]

export const teamBaseBannerUrls = {
  2023: `https://${MAIN_PLL_URL}/wp-content/uploads/2021/01`,
  2024: `https://${MAIN_PLL_URL}/wp-content/uploads/2024/05`,
  2025: `https://${MAIN_PLL_URL}/wp-content/uploads/2024/05`
}

export const teamDropdown = [
  { label: 'All Teams', value: 'All Teams' },
  { label: 'Archers', value: 'Archers' },
  { label: 'Atlas', value: 'Atlas' },
  { label: 'Cannons', value: 'Cannons' },
  { label: 'Chaos', value: 'Chaos' },
  { label: 'Outlaws', value: 'Outlaws' },
  { label: 'Redwoods', value: 'Redwoods' },
  { label: 'Waterdogs', value: 'Waterdogs' },
  { label: 'Whipsnakes', value: 'Whipsnakes' },
]

export const teamLocationCodes = {
  ARC: 'UTA',
  ATL: 'NYA',
  CAN: 'BOS',
  CHA: 'CAR',
  OUT: 'DEN',
  RED: 'CAL',
  WAT: 'PHI',
  WHP: 'MDW'
}

export const teamsBasicArray = [
  {
    officialId: 'ARC',
    fullName: 'Archers'
  },
  {
    officialId: 'ATL',
    fullName: 'Atlas',
  },
  {
    officialId: 'CAN',
    fullName: 'Cannons',
  },
  {
    officialId: 'CHA',
    fullName: 'Chaos',
  },
  {
    officialId: 'OUT',
    fullName: 'Outlaws',
  },
  {
    officialId: 'CHR',
    fullName: 'Chrome',
  },
  {
    officialId: 'RED',
    fullName: 'Redwoods',
  },
  {
    officialId: 'WAT',
    fullName: 'Waterdogs',
  },
  {
    officialId: 'WHP',
    fullName: 'Whipsnakes',
  },
  {
    officialId: 'ZPP',
    fullName: 'Player Pool',
  },
  {
    officialId: 'ASH',
    fullName: 'Veterans',
  },
  {
    officialId: 'ASA',
    fullName: 'Rising Stars',
  },
  {
    officialId: 'VET',
    fullName: 'Veterans',
  },
  {
    officialId: 'RIS',
    fullName: 'Rising Stars',
  },
  {
    officialId: 'WCHR',
    fullName: 'Charm',
  },
  {
    officialId: 'WCHA',
    fullName: 'Charging',
  },
  {
    officialId: 'WGUA',
    fullName: 'Guard',
  },
  {
    officialId: 'WPLM',
    fullName: 'Palms',
  }
]

export const standingsHeaders = [
  { code: 'W', label: 'Wins' },
  { code: 'L', label: 'Losses' },
  { code: 'T', label: 'Ties' },
  { code: 'SF', label: 'Scores For' },
  { code: 'SA', label: 'Scores Against' },
  { code: 'Diff', label: 'Score Differential' },
]

export const teamFullTableHeaders = [
  { W: 'Wins' },
  { L: 'Losses' },
  { S: 'Scores: Goals + Two Point Goals(2)' },
  { '1G': 'One Point Goals' },
  { '2G': 'Two Point Goals' },
  { A: 'Assists' },
  { Sh: 'Shots' },
  { 'Sh%': 'Shooting Percentage' },
  { '2Sh%': 'Two Point Shooting Percentage' },
  { SOG: 'Shots On Goal' },
  { TO: 'Turnovers' },
  { CT: 'Caused Turnovers' },
  { GB: 'Groundballs' },
  { FO: 'Faceoffs Won - Total Faceoffs Taken' },
  { 'FO%': 'Percentage of Faceoffs Won To Total Faceoffs' },
  { Sv: 'Saves' },
  { 'Sv%': 'Save Percentage' },
  { SA: 'Scores Against' },
  { SAA: 'Scores Against Average' },
  { PEN: 'Number of Penalties' },
  { PIM: 'Penalties In Minutes' },
  { 'PP%': 'Power Play Percentage' },
  { PP: 'Power Plays' },
  { PPSh: 'Power Play Shots' },
  { 'PK%': 'Penalty Kill Percentage' },
  { PK: 'Penalty Kills' },
]

export const teamAllStatsTableHeaders = [
  { S: 'Scores: Goals + Two Point Goals(2)' },
  { '1G': 'One Point Goals' },
  { '2G': 'Two Point Goals' },
  { A: 'Assists' },
  { Sh: 'Shots' },
  { 'Sh%': 'Shooting Percentage' },
  { SOG: 'Shots On Goal' },
  { TO: 'Turnovers' },
  { CT: 'Caused Turnovers' },
  { GB: 'Groundballs' },
  { FO: 'Faceoffs Won - Total Faceoffs Taken' },
  { 'FO%': 'Percentage of Faceoffs Won To Total Faceoffs' },
  { Sv: 'Saves' },
  { 'Sv%': 'Save Percentage' },
  { SA: 'Scores Against' },
  { PEN: 'Number of Penalties' },
  { PIM: 'Penalties In Minutes' },
  { 'PP%': 'Power Play Percentage' },
  { PP: 'Power Plays' },
  { 'PK%': 'Penalty Kill Percentage' },
  { PK: 'Penalty Kills' },
  { TCH: 'Touches' },
  { PAS: 'Total Passes' },
]

export const teamHashTable = {
  W: 'teamWins',
  L: 'teamLosses',
  S: 'scores',
  '1G': 'onePointGoals',
  '2G': 'twoPointGoals',
  A: 'assists',
  Sh: 'shots',
  'Sh%': 'shotPct',
  '2Sh%': 'twoPointShotPct',
  SOG: 'shotsOnGoal',
  TO: 'turnovers',
  CT: 'causedTurnovers',
  GB: 'groundBalls',
  FO: 'faceoffsWon',
  'FO%': 'faceoffPct',
  Sv: 'saves',
  'Sv%': 'savePct',
  SA: 'scoresAgainst',
  SAA: 'saa',
  PEN: 'numPenalties',
  PIM: 'pim',
  'PP%': 'powerPlayPct',
  PP: 'powerPlayGoals',
  PPSh: 'powerPlayShots',
  'PK%': 'manDownPct',
  PK: 'manDownPct',
}

export const teamCSVHeaders: {displayName: string, id: string}[] = [
  { displayName: 'Team ID', id: 'officialId' },
  { displayName: 'Team', id: 'team' },
  { displayName: 'Scores', id: 'scores' },
  { displayName: 'Goals', id: 'goals' },
  { displayName: 'One Point Goals', id: 'onePointGoals' },
  { displayName: 'Two Point Goals', id: 'twoPointGoals' },
  { displayName: 'Assists', id: 'assists' },
  { displayName: 'Shots', id: 'shots' },
  { displayName: 'Shot Pct', id: 'shotPct' },
  { displayName: 'Two Point Shots', id: 'twoPointShots' },
  { displayName: 'Two Point Shot Pct', id: 'twoPointShotPct' },
  { displayName: 'SOG', id: 'shotsOnGoal' },
  { displayName: 'SOG Pct', id: 'shotsOnGoalPct' },
  { displayName: 'Two Point SOG', id: 'twoPointShotsOnGoal' },
  { displayName: 'Groundballs', id: 'groundBalls' },
  { displayName: 'Turnovers', id: 'turnovers' },
  { displayName: 'Caused Turnovers', id: 'causedTurnovers' },
  { displayName: 'Faceoff Pct', id: 'faceoffPct' },
  { displayName: 'Faceoffs Won', id: 'faceoffsWon' },
  { displayName: 'Faceoffs Lost', id: 'faceoffsLost' },
  { displayName: 'Saves', id: 'saves' },
  { displayName: 'Save Pct', id: 'savePct' },
  { displayName: 'SAA', id: 'saa' },
  { displayName: 'Penalties', id: 'numPenalties' },
  { displayName: 'PIM', id: 'pim' },
  { displayName: 'Two Point Goals Against', id: 'twoPointGoalsAgainst' },
  { displayName: 'Two Point GAA', id: 'twoPtGaa' },
  { displayName: 'Power Plays', id: 'timesManUp' },
  { displayName: 'Power Play Pct', id: 'powerPlayPct' },
  { displayName: 'Power Play Goals', id: 'powerPlayGoals' },
  { displayName: 'Power Play Shots', id: 'powerPlayShots' },
  { displayName: 'Times Short Handed', id: 'timesShortHanded' },
  { displayName: 'Penalty Kill Pct', id: 'manDownPct' },
  { displayName: 'Power Play Goals Against', id: 'powerPlayGoalsAgainst' },
  { displayName: 'Passes', id: 'totalPasses' }
]

export const TEAM_LOGO_IMAGES: { [key: string]: string } = {
  archers: `${Team_LOGO_URL}/2024/Logo/2024_archers_primary_color.png`,
  atlas: `${Team_LOGO_URL}/2024/Logo/2024_atlas_primary_color.png`,
  cannons: `${Team_LOGO_URL}/2024/Logo/2024_cannons_primary_color.png`,
  chaos: `${Team_LOGO_URL}/2024/Logo/2024_chaos_primary_color.png`,
  outlaws: `${Team_LOGO_URL}/2024/Logo/2024_outlaws_primary_color.png`,
  redwoods: `${Team_LOGO_URL}/2024/Logo/2024_redwoods_primary_color.png`,
  waterdogs: `${Team_LOGO_URL}/2024/Logo/2024_waterdogs_primary_color.png`,
  whipsnakes: `${Team_LOGO_URL}/2024/Logo/2024_whipsnakes_primary_color.png`,
  chrome: `https://${MAIN_PLL_URL}/chrome_primary_fullcolor`,
  baptiste: `${Team_LOGO_URL}/2022/Logo/team-baptiste.png`,
  farrell: `${Team_LOGO_URL}/2022/Logo/team-farrell.png`,
  adversaries: `${Team_LOGO_URL}/2021/07/adversaries.jpg`,
  defenders: `${Team_LOGO_URL}/2021/07/defenders.jpg`,
  veterans: `${Team_LOGO_URL}/2023/Logo/2023_ASG_veterans.webp`,
  'rising stars': `${Team_LOGO_URL}/2023/Logo/2023_ASG_rising stars.webp`,
  'player pool': `https://${MAIN_PLL_URL}/wp-content/uploads/2024/03/shield-bg.png`,
  east: `https://${MAIN_PLL_URL}/wp-content/uploads/2024/06/24ASG_EIcon_FullColor.webp`,
  west: `https://${MAIN_PLL_URL}/wp-content/uploads/2024/06/24ASG_WIcon_FullColor.webp`,
  charm: `${Team_LOGO_URL}/2024/Logo/wll_maryland_charm_logo_primary.png`,
  palms: `${Team_LOGO_URL}/2024/Logo/wll_california_palms_logo_primary.png`,
  charging: `${Team_LOGO_URL}/2024/Logo/wll_new_york_charging_logo_primary.png`,
  guard: `${Team_LOGO_URL}/2024/Logo/wll_boston_guard_logo_primary.png`,
}
