import React, { useEffect, useState } from 'react'
import { SignInModal } from '../../Components/Auth'
import { getFirAuth, getActionCodeSettings, getGoogleProvider } from './firebaseConnect'
import { getCurrentUser, NO_USER, setUpdatedUserData } from './AuthData'
import {
  checkIfEmbededInNativeApp,
  logEvent,
  resetWindowLocationWithoutParams,
  useCookieStorage,
} from '../../Utils'
import { useQuery } from '../../Routing'
import { User } from '../Users/types'
import { logBrazeEvent } from '../../Utils/analytics/brazeAnalytics'
import { BRAZE_EVENTS } from '../../Constants/analytics'
import { getAuth, type ConfirmationResult } from 'firebase/auth'
import { initBraze } from '../../Config/Braze/braze.config'
import { createUser, getUser } from '../Users'

type AuthContextType = User & {
  authLoading: boolean
  brazeInitialized: boolean
  login: (extraFields?: string[]) => void
  logout: () => void
  handleToggleLogin: () => void
  updateProfileInState: (userItem: any) => void
  sendPhoneVerification: (phone: string) => Promise<ConfirmationResult | null>
  getMissingUserFields: (fields: string[]) => string[]
}

export const AuthContext = React.createContext<AuthContextType | null>(null)

type AuthProviderProps = { allowAnonymous?: boolean; children?: React.ReactNode }

export const AuthProvider: React.FC<AuthProviderProps> = ({
  allowAnonymous = false,
  children,
}) => {
  let query = useQuery()
  const { getCookie, setCookie, removeCookie } = useCookieStorage()
  const [user, setUser] = useState<User>(NO_USER)
  const [authLoading, setAuthLoading] = useState<boolean>(true)
  const [showLogin, setShowLogin] = useState<{
    show: boolean
    extraFields?: string[]
  }>({ show: false, extraFields: [] })
  const [brazeInitialized, setBrazeInitialized] = useState<boolean>(false)

  useEffect(() => {
    const handleAuthChange = async () => {
      await onAuthChange()
    }
    //Check if pending redirect
    getAuthRedirectResult()

    handleAuthChange() //Turn on auth listener

    //Cleanup
    return () => {
      onAuthChange()
    }
  }, [])

  /**
   * When the auth state changes, either user is logged in or logged out
   */
  const onAuthChange = async () => {
    const auth = await getFirAuth()
    if (auth) {
      const { isSignInWithEmailLink, onAuthStateChanged } = await import('firebase/auth')
      if (isSignInWithEmailLink(auth, window.location.href)) {
        //Email link signup
        handleEmailLink()
      }
      //Check custom token query param
      let fbctQuery = query.get('fbct')
      if (fbctQuery) {
        handleCustomToken(fbctQuery)
      }

      onAuthStateChanged(auth, async (userRes) => {
        setAuthLoading(true)

        if (userRes) {
          // User is logged in
          await handleUserChange()
        } else {
          // User is logged out

          if (allowAnonymous) {
            handleAnonymousSignIn()
          }
          setShowLogin({ show: false, extraFields: [] })
          setAuthLoading(false)
        }
      })
    } else {
      setUser(NO_USER)
      setAuthLoading(false)
      setShowLogin({ show: false, extraFields: [] })
      if (checkIfEmbededInNativeApp()) {
        logEvent(`firebase_not_initialized_onAuthChange_embeded`)
      } else {
        logEvent(`firebase_not_initialized_onAuthChange`)
      }
    }
  }

  const handleUserChange = async () => {
    const usr = await getCurrentUser() //Firebase Auth user
    if (!usr.isAnonymous && usr.isLoggedIn) {
      const isNewUser = checkFirstTimeUser()
      const dbUser = isNewUser ? await createUser(usr) : await getUser(usr)
      if (dbUser) {
        setUserDataInState(dbUser)
        //lazy load braze
        initBraze(usr.uid!).then((success) => {
          if (success) {
            setBrazeInitialized(true)
          }
        })
      }
    } else {
      setUser(usr as User)
      setShowLogin({ show: false, extraFields: [] })
      setAuthLoading(false)
    }
  }

  const checkFirstTimeUser = () => {
    let user = getAuth()?.currentUser
    if (!user?.metadata?.creationTime || !user?.metadata?.lastSignInTime) return false
    const lastSignInTime = new Date(user?.metadata?.lastSignInTime).getTime()
    const creationTime = new Date(user?.metadata?.creationTime).getTime()
    return lastSignInTime - creationTime < 5000
  }

  const setUserDataInState = async (userRes: User) => {
    const userAuth = await setUpdatedUserData(userRes)
    setUser(userAuth)
    setAuthLoading(false)
  }

  /**
   * Update profile in state
   */
  const updateProfileInState = (userItem: any) => {
    const currentAuth = { ...user }
    if (userItem.favTeam) {
      setUser({ ...currentAuth, ...userItem })
      return
    }
    setUser({ ...currentAuth, ...userItem })
    return
  }

  /**
   * Sign user in anonymously
   */
  const handleAnonymousSignIn = async () => {
    const auth = await getFirAuth()
    if (!auth) return
    const { signInAnonymously } = await import('firebase/auth')
    await signInAnonymously(auth)
  }

  const handleEmailLink = async () => {
    const auth = await getFirAuth()
    if (auth) {
      let email = ''
      if (window?.localStorage) {
        email = window.localStorage.getItem('pll_temp_em') || ''
      }
      if (!email) {
        email = tryEmFromCookie()
      }
      if (!email) {
        const em = window.prompt('Please confirm your email')
        if (em) {
          email = em.toLowerCase()
        }
      }
      try {
        const { signInWithEmailLink } = await import('firebase/auth')
        const result = await signInWithEmailLink(auth, email!, window.location.href)
        if (result?.user?.uid) {
          window.localStorage.removeItem('pll_temp_em')
          removeCookie('pll_temp_em')
          loginWithBraze(result.user.uid)
        }
      } catch (err) {
        logEvent('loginError_handleEmailLink', {
          provider: 'email',
          error: err,
          product: 'stats',
        })
        console.log(err)
      }
    }
  }

  /**
   * Check temp email from cookie
   */
  const tryEmFromCookie = () => {
    let email = ''
    const cookieEm = getCookie('pll_temp_em')

    if (cookieEm) {
      email = cookieEm.toLowerCase()
    }
    return email
  }

  const signInWithSocial = async (provider: string) => {
    const auth = await getFirAuth()
    if (auth) {
      if (provider !== 'google') return
      try {
        await signInWithGooglePopup(auth)
      } catch (err: any) {
        console.log(err)
        logEvent('loginError_signInWithSocial', {
          provider: provider,
          error: err,
          product: 'f2p',
        })
        setShowLogin({ show: !setShowLogin, extraFields: [] })
        throw new Error(err)
      }
    }
  }

  /**
   * Use sign up with popup for just Gmail
   */
  const signInWithGooglePopup = async (auth: any) => {
    const p = await getGoogleProvider()
    if (!p) return
    try {
      const { signInWithPopup } = await import('firebase/auth')
      let result = await signInWithPopup(auth, p)
      if (result?.user?.uid) {
        loginWithBraze(result.user.uid)
        setShowLogin({ show: !setShowLogin, extraFields: [] })
      }
    } catch (err: any) {
      logEvent('loginError_signInWithGooglePopup', {
        provider: 'google',
        error: err,
        product: 'web',
      })
    }
  }

  const getAuthRedirectResult = async () => {
    const auth = await getFirAuth()
    if (auth) {
      try {
        const { getRedirectResult } = await import('firebase/auth')
        let result = await getRedirectResult(auth)
        if (result?.user?.uid) {
          loginWithBraze(result.user.uid)
        }
      } catch (err: any) {
        if (!err.credential) return
        const { signInWithCredential } = await import('firebase/auth')
        await signInWithCredential(auth, err.credential)
      }
    }
  }

  /**
   * Handle if custom token is passed in
   */
  const handleCustomToken = async (tk: string) => {
    const auth = await getFirAuth()
    if (auth) {
      try {
        const { signInWithCustomToken } = await import('firebase/auth')
        const result = await signInWithCustomToken(auth, tk)
        if (result?.user?.uid) {
          loginWithBraze(result.user.uid)
          resetWindowLocationWithoutParams(['fbct'])
        }
      } catch (err) {
        console.log(err)
        resetWindowLocationWithoutParams(['fbct'])
      }
    }
  }

  const signInWithEmLink = async (redirectTo: string, email: string) => {
    const auth = await getFirAuth()
    if (auth) {
      let actionCodeSettings = getActionCodeSettings(
        redirectTo || window.location.hostname
      )
      try {
        const { sendSignInLinkToEmail } = await import('firebase/auth')
        await sendSignInLinkToEmail(auth, email, actionCodeSettings)
        window.localStorage.setItem('pll_temp_em', email)
        setCookie('pll_temp_em', email)
        return true
      } catch (err: any) {
        return false
      }
    }
    return false
  }

  // PHONE LOGIN //
  const sendPhoneVerification = async (phoneNumber: string) => {
    const auth = await getFirAuth()
    const { signInWithPhoneNumber } = await import('firebase/auth')
    let verifier = await getAppVerifier('phone-signin-btn', auth)
    if (verifier && auth) {
      try {
        let confirmationResult: ConfirmationResult = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          verifier
        )
        return confirmationResult
      } catch (err) {
        console.log(err)
        logEvent('loginError_sendPhoneVerification', {
          provider: 'phone',
          error: err,
          product: 'stats',
        })
        return null
      }
    } else {
      return null
    }
  }

  const getAppVerifier = async (
    verifierId: string | HTMLElement,
    auth: any | undefined
  ) => {
    if (auth) {
      const { RecaptchaVerifier } = await import('firebase/auth')
      return new RecaptchaVerifier(auth, verifierId, {
        size: 'invisible',
      })
    } else {
      return null
    }
  }

  const login = (extraFields?: string[]) => {
    if (extraFields) {
      setShowLogin({ show: true, extraFields })
      return
    }
    setShowLogin({ show: true, extraFields: [] })
  }

  const handleToggleLogin = () => {
    setShowLogin({ show: !setShowLogin, extraFields: [] })
  }

  const logout = async () => {
    const fba = await getFirAuth()
    if (fba) {
      setUser(NO_USER)
      fba.signOut()
      setBrazeInitialized(false)
      setShowLogin({ show: false, extraFields: [] })
      setAuthLoading(false)
    }
  }

  const loginWithBraze = async (userId: string, shouldSendEvent: boolean = true) => {
    //shouldSendEvent false for logging in through embeded browser
    if (!brazeInitialized) {
      const success = await initBraze(userId)
      if (success) {
        setBrazeInitialized(true)
        shouldSendEvent && logBrazeEvent(BRAZE_EVENTS.log_in)
      }
    } else {
      shouldSendEvent && logBrazeEvent(BRAZE_EVENTS.log_in)
    }
  }

  /**
   * Get fields user has not filled in on profile
   */
  const getMissingUserFields = (fields: string[]): string[] => {
    if (fields.length === 0) return []
    return fields.filter((field) => field in user && !user[field as keyof User])
  }

  return (
    <AuthContext.Provider
      value={{
        ...user,
        authLoading,
        brazeInitialized,
        handleToggleLogin,
        login,
        logout,
        sendPhoneVerification,
        getMissingUserFields,
        updateProfileInState,
      }}
    >
      {showLogin.show && (
        <SignInModal
          onClose={() => {
            logEvent('login_cancelled')
            handleToggleLogin()
          }}
          signInWithSocial={signInWithSocial}
          signInWithEmLink={signInWithEmLink}
        />
      )}
      {children}
    </AuthContext.Provider>
  )
}
