export const initBraze = async (userId: string) => {
  if (process.env.REACT_APP_BRAZE_API_KEY) {
    const { initialize, changeUser, openSession } = await import('@braze/web-sdk')
    let success = initialize(process.env.REACT_APP_BRAZE_API_KEY, {
      baseUrl: process.env.REACT_APP_BRAZE_API_URL || 'sdk.iad-05.braze.com',
    })

    if (success) {
      console.log('Braze initialized, updating user and session')
      changeUser(userId)
      openSession()
      return true
    }
  }
    return false
}