import { EventGQL, PlayLogs, WinProbTeam } from '../../Api'
import { GameWinProbability, MinGameWinProbability } from '../../Api/events/events.types'
import { CHAMP_SERIES_PERIOD_SECONDS, PERIOD_SECONDS } from '../../Constants/season'
import { getTeamColor } from '../TeamHelpers/teamHelpers'
import { Percent2Digits } from '../formatting'

export const getLatestWinProb = (logs: GameWinProbability[], event: EventGQL) => {
  const latestLog = logs[logs.length - 1]
  return generateWinProbObj(latestLog, event)
}

export const getWinProbById = (markerId: string, event: EventGQL) => {
  if (!event.liveWinProbablities) return null

  const foundLog = event.liveWinProbablities.find(
    (log) => log.markerId === markerId
  )
  if (!foundLog) return null

  return generateWinProbObj(foundLog, event)
}

export const getWinProbByTime = (secondsPassed: number, event: EventGQL) => {
  if (!event.liveWinProbablities) return null

  const foundLog = event.liveWinProbablities.find(
    (log) => log.secondsPassed === secondsPassed
  )
  if (!foundLog) return null

  return generateWinProbObj(foundLog, event)
}

export const generateWinProbObj = (
  log: GameWinProbability | MinGameWinProbability,
  event: EventGQL
) => {
  let leadingTeamProb: WinProbTeam | MinGameWinProbability | null = null

  if (log.awayTeamWinProbability > log.homeTeamWinProbability) {
    leadingTeamProb = {
      team: event.awayTeam.officialId,
      probability: log?.awayTeamWinProbability || 0,
      color: getTeamColor(event.awayTeam.officialId),
    }
  } else {
    leadingTeamProb = {
      team: event.homeTeam.officialId,
      probability: log?.homeTeamWinProbability || 0,
      color: getTeamColor(event.homeTeam.officialId),
    }
  }
  return leadingTeamProb
}

export const getPeriodLabel = (period: number): string => {
  // Determine if it's a quarter or an overtime period
  if (period <= 4) {
    return `Q${period}` // Regular quarters (Q1-Q4)
  } else if (period <= 7) {
    return `OT${period - 4}` // Overtime periods (OT1-OT3)
  } else {
    return 'Game Over' // Time exceeds the normal game length and overtimes
  }
}

export const getPeriodSecondsLength = (seasonSegment?: string): number =>
  seasonSegment === 'champseries' ? CHAMP_SERIES_PERIOD_SECONDS : PERIOD_SECONDS

const calcRegularPeriodSeconds = (period: number, periodSecondsLength: number): number =>
  Math.min(period, 4) * periodSecondsLength

const calcOvertimeSeconds = (
  overtimePeriods: number,
  seasonSegment?: string,
  periodSecondsLength?: number
): number =>
  overtimePeriods *
  (seasonSegment === 'champseries' ? CHAMP_SERIES_PERIOD_SECONDS : periodSecondsLength!)

const adjustForCurrentPeriod = (
  totalSeconds: number,
  clockMinutes?: number,
  clockSeconds?: number,
  periodSecondsLength?: number
): number => {
  if (clockMinutes !== undefined && clockSeconds !== undefined && periodSecondsLength) {
    const currentPeriodSecondsPassed =
      periodSecondsLength - (clockMinutes * 60 + clockSeconds)
    return totalSeconds - (periodSecondsLength - currentPeriodSecondsPassed)
  }
  return totalSeconds
}

export const calcTotalGameTime = (
  period: number,
  clockMinutes?: number,
  clockSeconds?: number,
  seasonSegment?: string
): number => {
  const periodSecondsLength = getPeriodSecondsLength(seasonSegment)

  if (!period) return periodSecondsLength

  let totalSeconds = calcRegularPeriodSeconds(period, periodSecondsLength)

  if (period > 4) {
    const overtimePeriods = period - 4
    totalSeconds += calcOvertimeSeconds(
      overtimePeriods,
      seasonSegment,
      periodSecondsLength
    )
    totalSeconds = adjustForCurrentPeriod(
      totalSeconds,
      clockMinutes,
      clockSeconds,
      periodSecondsLength
    )
  }

  return totalSeconds
}

export type PlayLogProbDescObj = {
  probability: number
  playLogs: PlayLogs[]
}

export const createWinProbDesc = ({ probability, playLogs }: PlayLogProbDescObj) => {
  let percent = createWinProbPct(probability)
  let desc = ''
  if (playLogs.length === 1) {
    desc = playLogs[0].description
  }
  if (playLogs.length > 1) {
    const firstLog = playLogs[0]
    const lastLog = playLogs[playLogs.length - 1]
    if (!lastLog.descriptor) return percent
    if (lastLog.descriptor.includes('SHOT GOOD')) {
      //Show goal before next faceoff
      desc = `${lastLog.description} ${firstLog.description}`
    } else {
      desc = `${firstLog.description} ${lastLog.description}`
    }
  }
  return `${percent} ${desc ?? ``}`
}

export const createWinProbPct = (probability: number) => {
  if (probability === 1) return '100%'
  if (probability > .9999) return `99.99%` //Don't allow 100% with rounding
  return Percent2Digits(probability)
}
