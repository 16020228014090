import lazyImport from '../../Utils/Loading/loadingHelpers'

// Utilities //
export const RefreshIcon = lazyImport(() => import('@mui/icons-material/Refresh'))

// ARROWS //
export const ExpandMoreIcon = lazyImport(() => import('@mui/icons-material/ExpandMore'))

export const IosShareIcon = lazyImport(() => import('@mui/icons-material/IosShare'))

// PROFILE //
export const AccountCircleOutlined = lazyImport(() => import('@mui/icons-material/AccountCircleOutlined'))

// SOCIAL //
export const GoogleIcon = lazyImport(() => import('@mui/icons-material/Google'))

