import React, { createContext, useState, useEffect, useContext, useRef } from 'react'
import { SeasonContext } from '../SeasonContext'
import {
  getAllPlayers,
  getAllTeams,
  fetchSeasonEvents,
  fetchAllPlayersFantasyStats,
  EventGQL,
  PlayerData,
  Team,
} from '../../Api'
import { Season, SeasonType } from '../SeasonContext/SeasonContext'
import {
  applyLeaderBoundaries,
  getLeaderTeam,
  PlayerSortKeys,
  sortPlayerFunc,
} from '../../Utils/PlayerHelpers/playerHelpers'
import { filterPLLTeams } from '../Controllers/Utils/filtering'
import {
  filterChampSeriesTeams,
  sortTeamsFunc,
} from '../../Utils/TeamHelpers/teamHelpers'
import { isChampSeriesLive } from '../../Config/League/league.config'
import { determineWeekForFantasy, isLiveGame, updateInProgressGame } from '../../Utils'
import { fetchLeagueLeaders } from '../../Api/players/players.api'
import {
  csLeadersStatArray,
  seasonLeadersStatArray,
} from '../../Constants/PlayerConstants'
import { LeagueLeader } from '../../Api/players/players.types'
import { StorageContext } from '../StorageContext'
import { STORAGE_NAMES } from '../../Constants'

type StatsContextType = {
  isPlayersLoading: boolean
  isTeamsLoading: boolean
  isEventsLoading: boolean
  isLeagueLeadersLoading: boolean
  isFantasyLoading: boolean
  players: PlayerData[]
  postPlayers: PlayerData[]
  csPlayers: PlayerData[]
  fantasyPlayers: PlayerData[]
  teams: Team[]
  events: EventGQL[]
  leagueLeaders: LeagueLeader[]
  yearObj: Season
  filterLeadersByStat: (stat: PlayerSortKeys) => LeagueLeader[]
  fetchLeaders: (season: Season, limit?: number, avgOn?: boolean) => Promise<void>
  getTopTeamsByStat: (stat: string, limit: number) => Team[]
  getTopPlayersByTeamAndStat: (
    teamId: string,
    stat: PlayerSortKeys,
    limit: number
  ) => PlayerData[]
  fetchFantasyStats: (season: Season) => Promise<void>
}

export const StatsContext = createContext<StatsContextType | null>(null)

export const StatsProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { seasonSelect } = useContext(SeasonContext)!
  const { isStorageReady, getStoredData, store } = useContext(StorageContext)!
  const [yearObj, setYearObj] = useState<Season>(seasonSelect)
  const [isPlayersLoading, setIsPlayersLoading] = useState<boolean>(true)
  const [isTeamsLoading, setIsTeamsLoading] = useState<boolean>(true)
  const [isEventsLoading, setIsEventsLoading] = useState<boolean>(true)
  const [isLeagueLeadersLoading, setIsLeagueLeadersLoading] = useState<boolean>(true)
  const [isFantasyLoading, setIsFantasyLoading] = useState<boolean>(true)
  const [players, setPlayers] = useState<PlayerData[]>([])
  const [postPlayers, setPostPlayers] = useState<PlayerData[]>([])
  const [csPlayers, setCSPlayers] = useState<PlayerData[]>([])
  const [fantasyPlayers, setFantasyPlayers] = useState<PlayerData[]>([])
  const [leagueLeaders, setLeagueLeaders] = useState<LeagueLeader[]>([])
  const [teams, setTeams] = useState<Team[]>([])
  let [events, setEvents] = useState<EventGQL[]>([])
  const gameCheckIntervalRef = useRef<any | null>(null)
  const liveGameIntervalRef = useRef<any | null>(null)

  useEffect(() => {
    if (isStorageReady) {
      fetchLeaders(yearObj)
      fetchData(yearObj)
    }
    return () => {
      clearIntervals()
    }
  }, [isStorageReady])

  useEffect(() => {
    if (events.length > 0) runCheckForLiveGameInterval(events)
  }, [events])

  useEffect(() => {
    const yearChanged = seasonSelect.year !== yearObj.year
    const seasonTypeChanged = seasonSelect.seasonType !== yearObj.seasonType
    if (seasonTypeChanged && !yearChanged) {
      setIsLeagueLeadersLoading(true)
      fetchLeaders(seasonSelect)
      fetchPlayersBySeason(seasonSelect) //Check to only fetch players if they are not already fetched
      return
    }
    if (yearChanged) {
      // Refetch & update all stats
      setIsLeagueLeadersLoading(true)
      fetchLeaders(seasonSelect)
      setPlayers([])
      setCSPlayers([])
      setPostPlayers([])
      fetchData(seasonSelect)
      fetchFantasyStats(seasonSelect)
    }
  }, [seasonSelect])

  const fetchData = async (select: Season) => {
    await Promise.all([
      fetchAllPlayers(select),
      fetchAllTeams(select),
      fetchAllEvents(select),
    ])
    setYearObj(select)
    return
  }

  const fetchPlayersBySeason = async (season: Season) => {
    if (
      (season.seasonType === 'champseries' && csPlayers.length < 1) ||
      (season.seasonType === 'post' && postPlayers.length < 1) ||
      (season.seasonType === 'regular' && players.length < 1)
    ) {
      await fetchAllPlayers(season)
    }
    setYearObj(season)
  }

  // PLAYER FETCHING //
  const fetchAllPlayers = async (season: Season) => {
    setIsPlayersLoading(true)
    if (season.seasonType === 'regular') {
      const { data } = await getStoredSeasonPlayers(season.year)
      if (data) {
        setPlayers(data)
        setIsPlayersLoading(false)
      }
    }
    try {
      let allPlrs = await getAllPlayers(season.year, season.seasonType)
      if (allPlrs && allPlrs.length > 0) {
        let allPWithTeam = extractPlayerTeam(allPlrs)
        setPlayersBySeasonType(allPWithTeam, season.seasonType)
        setIsPlayersLoading(false)
        if (season.seasonType === 'regular') {
          cacheNewSeasonPlayer(allPWithTeam, season.year)
        }
      }
      return
    } catch (err) {
      console.log(err)
      setIsPlayersLoading(false)
      return
    }
  }

  const setPlayersBySeasonType = (players: PlayerData[], seasonType: SeasonType) => {
    if (seasonType === 'champseries') {
      setCSPlayers(players)
    } else if (seasonType === 'post') {
      setPostPlayers(players)
    } else {
      setPlayers(players)
    }
  }

  const getStoredSeasonPlayers = async (
    season: number
  ): Promise<{
    data: PlayerData[] | null
    refetch: boolean
  }> => {
    return await getStoredData(`${season}_${STORAGE_NAMES.season_players}`, 30000)
  }

  const cacheNewSeasonPlayer = async (
    plrs: PlayerData[],
    season: number
  ): Promise<void> => {
    await store(`${season}_${STORAGE_NAMES.season_players}`, plrs)
  }

  const fetchLeaders = async (season: Season, limit: number = 5) => {
    if (season.seasonType === 'regular') {
      getStoredLeagueLeaders(season.seasonType)
    }
    const statsList =
      season.seasonType === 'champseries' ? csLeadersStatArray : seasonLeadersStatArray
    const leaders = await fetchLeagueLeaders(
      season.year,
      season.seasonType,
      statsList,
      limit
    )
    setLeagueLeaders(leaders)
    setIsLeagueLeadersLoading(false)
    if (season.seasonType === 'regular') {
      cacheNewLeagueLeaders(leaders, season.seasonType)
    }
  }

  const getStoredLeagueLeaders = async (seasonType: SeasonType): Promise<void> => {
    const storedLeaders = await getStoredData(
      `${STORAGE_NAMES.league_player_leaders}_${seasonType}`
    )
    if (storedLeaders?.data) {
      setLeagueLeaders(storedLeaders.data)
      setIsLeagueLeadersLoading(false)
    }
  }

  const cacheNewLeagueLeaders = async (
    ldrs: LeagueLeader[],
    seasonType: SeasonType
  ): Promise<void> => {
    await store(`${STORAGE_NAMES.league_player_leaders}_${seasonType}`, ldrs)
  }

  const fetchFantasyStats = async (season: Season) => {
    setIsFantasyLoading(true)
    try {
      //Don't return fantasy stats for years before 2021
      if (season.year < 2021) return
      let fantasyWeek = determineWeekForFantasy(season)
      let pRes = await fetchAllPlayersFantasyStats(season.year, fantasyWeek)
      setFantasyPlayers(pRes)
      setIsFantasyLoading(false)
    } catch (err) {
      console.log(err)
      setIsFantasyLoading(false)
    }
  }

  // TEAM FETCHING //
  const fetchAllTeams = async (season: Season, sortBy?: string) => {
    let teamRes: Team[] | null = []
    setIsTeamsLoading(true)
    try {
      teamRes = await getAllTeams(season.year, sortBy)
      if (teamRes.length > 0) {
        setTeams(teamRes)
      }
      setIsTeamsLoading(false)
    } catch (err) {
      console.log(err)
      setIsTeamsLoading(false)
    }
  }

  // EVENTS FETCHING //
  const fetchAllEvents = async (season: Season) => {
    if (season.seasonType === 'regular') {
      getStoredEvents(season.year)
    }
    try {
      const evs = await fetchSeasonEvents(season.year, isChampSeriesLive)
      if (evs && evs.length > 0) {
        setEvents(evs)
        if (season.seasonType === 'regular') {
          cacheNewEvents(evs, season.year)
        }
      }
      setIsEventsLoading(false)
    } catch (err) {
      console.log('Error getting events', err)
      setIsEventsLoading(false)
    }
  }

  const getStoredEvents = async (season: number): Promise<void> => {
    const storedEvents = await getStoredData(`${season}_${STORAGE_NAMES.seasonEvents}`)
    if (storedEvents?.data && storedEvents.data.length > 0) {
      setEvents(storedEvents.data)
      setIsEventsLoading(false)
    }
  }

  const cacheNewEvents = async (evs: EventGQL[], season: number): Promise<void> => {
    await store(`${season}_${STORAGE_NAMES.seasonEvents}`, evs)
  }

  const runCheckForLiveGameInterval = (seasonEvents: EventGQL[]) => {
    if (gameCheckIntervalRef.current === null) {
      //Starting interval to go every 3 seconds
      gameCheckIntervalRef.current = setInterval(() => {
        if (seasonEvents.length < 1) return clearInterval(liveGameIntervalRef.current)
        const liveEv = seasonEvents.find((ev) => isLiveGame(ev))
        if (liveEv) {
          startLiveGameInterval(liveEv)
        }
      }, 3000)
    } else {
      console.log('Game check interval running')
    }
  }

  const startLiveGameInterval = async (liveEv: EventGQL) => {
    if (!liveGameIntervalRef.current) {
      console.log('Starting live game interval')

      liveGameIntervalRef.current = setInterval(async () => {
        //Starting interval to go every 20 seconds
        try {
          const evInTimer = await updateInProgressGame(liveEv)
          if (evInTimer) updateSeasonEventInState(evInTimer)

          if (!evInTimer || !isLiveGame(evInTimer)) {
            if (liveGameIntervalRef.current !== null) {
              clearInterval(liveGameIntervalRef.current)
              liveGameIntervalRef.current = null
            }
          }
        } catch (err) {
          console.log('Error getting in progress game', err)
          clearInterval(liveGameIntervalRef.current!)
        }
      }, 20000)
    }
  }

  const updateSeasonEventInState = (evToUpdate: EventGQL) => {
    let copiedEvents = events
    if (copiedEvents.length > 0) {
      const oldEv = events.find((ev) => ev.slugname === evToUpdate.slugname)
      const oldEvIdx = events.findIndex((ev) => ev.slugname === evToUpdate.slugname)
      const merged = { ...oldEv, ...evToUpdate }
      if (!oldEvIdx) return
      copiedEvents[oldEvIdx] = merged
      setEvents([...copiedEvents])
    }
  }

  // LEADERS FUNCTIONS //

  const filterLeadersByStat = (stat: PlayerSortKeys) => {
    let ldrs = leagueLeaders.filter((ldr) => ldr.statType === stat)
    return ldrs
  }

  const getTopPlayersByStat = (
    stat: PlayerSortKeys,
    seasonSegment: string,
    limit: number,
    teamId?: string,
    league?: string
  ) => {
    let playerList: PlayerData[] = teamId
      ? players.filter((pl) => pl.team?.officialId === teamId)
      : applyLeaderBoundaries(players, stat, seasonSelect.seasonType)
    if (playerList.length < 1) return []

    let sorted = sortPlayerFunc(playerList, stat, seasonSegment, -1)
    if (league) {
      return sorted.filter((p) => p.league === league)
    }
    if (limit) {
      sorted = sorted.slice(0, limit)
    }
    return sorted
  }

  const extractPlayerTeam = (players: PlayerData[]) => {
    if (!players || players.length < 1) return []
    return players.map((plr) => {
      const tm = getLeaderTeam(seasonSelect.year, plr)
      if(!tm) return plr
      plr.team = tm
      return plr
    })
  }

  // TEAM FUNCTIONS //

  const getTopTeamsByStat = (stat: string, limit: number = 6) => {
    let list = [...teams]
    if (list.length === 0) {
      return []
    }
    if (seasonSelect.year === 2019 || seasonSelect.year === 2020) {
      list = filterPLLTeams(list)
    }
    if (seasonSelect.seasonType === 'champseries') {
      list = filterChampSeriesTeams(list, seasonSelect.year)
    }
    const sortedList = sortTeamsFunc(list, stat, seasonSelect.seasonType, -1).slice(
      0,
      limit
    )
    return sortedList
  }

  const getTopPlayersByTeamAndStat = (
    teamId: string,
    stat: PlayerSortKeys,
    limit: number = 6
  ) => {
    let ldrs = getTopPlayersByStat(stat, seasonSelect.seasonType, limit, teamId)
    let leadersWithBoundaries = applyLeaderBoundaries(
      ldrs,
      stat,
      seasonSelect.seasonType
    )
    return leadersWithBoundaries
  }

  const clearIntervals = () => {
    if (gameCheckIntervalRef.current !== null) {
      clearInterval(gameCheckIntervalRef.current)
      gameCheckIntervalRef.current = null
    }
    if (liveGameIntervalRef.current !== null) {
      clearInterval(liveGameIntervalRef.current)
      liveGameIntervalRef.current = null
    }
  }

  return (
    <StatsContext.Provider
      value={{
        isPlayersLoading,
        isTeamsLoading,
        isEventsLoading,
        isLeagueLeadersLoading,
        isFantasyLoading,
        players,
        postPlayers,
        csPlayers,
        fantasyPlayers,
        teams,
        events,
        leagueLeaders,
        yearObj,
        getTopTeamsByStat,
        getTopPlayersByTeamAndStat,
        fetchLeaders,
        filterLeadersByStat,
        fetchFantasyStats,
      }}
    >
      {children}
    </StatsContext.Provider>
  )
}
