export const logBrazeEvent = async (name: string, params?: any | undefined) => {
  try {
    if (!window?.braze) {
      return
    }
    const { logCustomEvent } = await import('@braze/web-sdk')
    logCustomEvent(name, params)
  } catch (e) {
    console.log(e)
  }
}

export const setBrazeAttribute = async (attributeName: string, property: any) => {
  try {
    if (!window?.braze) {
      return
    }
    const { getUser } = await import('@braze/web-sdk')
    let brazeUser = getUser()
    if (brazeUser) {
      brazeUser.setCustomUserAttribute(attributeName, property)
    }
  } catch (e) {
    console.log(e)
  }
}
