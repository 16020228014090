import React, { createContext, useState, useEffect } from 'react'
import {
  EventGQL,
  fetchEventByIdGQL,
  fetchLiveEventByIdGQL,
  fetchPlayLogsByEvent,
  PlayLogs,
} from '../../Api'

type BroadcastGameContextType = {
  gameEvent: EventGQL | null
  playLogs: PlayLogs[]
  isEventLoading: boolean
  isLoadingPlayLogs: boolean
  fetchPlayLogs: (event: EventGQL) => Promise<void>
}

export const BroadcastGameContext = createContext<BroadcastGameContextType | null>(null)

export const BroadcastGameProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [gameEvent, setGameEvent] = useState<EventGQL | null>(null)
  const [playLogs, setPlayLogs] = useState<PlayLogs[]>([])
  const [isEventLoading, setIsEventLoading] = useState<boolean>(true)
  const [isLoadingPlayLogs, setIsLoadingPlayLogs] = useState<boolean>(true)
  const [fetchFails, setFetchFails] = useState<{ [key: string]: number }>({
    event: 0,
    playLogs: 0,
  })
  const [isRequestPending, setIsRequestPending] = useState<boolean>(false)
  const [isLogRequestPending, setIsLogRequestPending] = useState<boolean>(false)

  // Reset page if too many failures
  useEffect(() => {
    if (fetchFails.event >= 5 || fetchFails.playLogs >= 5) {
      console.error('Too many fetch failures, resetting page...')
      setInterval(() => {
        window.location.reload()
      }, 3000)
    }
  }, [fetchFails])

  useEffect(() => {
    let slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    const id = slug.split('?')[0]
    fetchEvent(id)
  }, [])

  const fetchEvent = async (id: string) => {
    try {
      const ev = await fetchEventByIdGQL(id)
      setGameEvent(ev)
      setIsEventLoading(false)
      await checkLiveGame(ev)
      return
    } catch (err) {
      console.error(err)
      setIsEventLoading(false)
      return
    }
  }

  const checkLiveGame = async (ev: EventGQL) => {
    if (!ev) return

    await fetchPlayLogs(ev)
    if (ev?.eventStatus === 1 || isWithin30Mins(ev)) {
      try {
        if (isRequestPending) return

        setIsRequestPending(true)
        const liveEv = await fetchLiveEventByIdGQL(ev.slugname)
        setIsRequestPending(false)

        if (!liveEv) {
          setFetchFails((prev) => ({ ...prev, event: prev.event + 1 }))
          return
        }

        // Reset fails on successful fetch
        setFetchFails((prev) => ({ ...prev, event: 0 }))
        setGameEvent(liveEv)
        await fetchPlayLogs(liveEv)

        const interval = setInterval(async () => {
          if (isRequestPending) {
            console.log('Request is pending:', isRequestPending)
            return
          }

          try {
            setIsRequestPending(true)
            console.log('Getting Live event')
            const updatedEv = await fetchLiveEventByIdGQL(ev.slugname)
            setIsRequestPending(false)

            if (!updatedEv) {
              return
            }

            // Reset fails on successful fetch
            setFetchFails((prev) => ({ ...prev, event: 0 }))

            if (updatedEv?.eventStatus >= 2) {
              setGameEvent(updatedEv)
              clearInterval(interval)
              return
            }

            setGameEvent(updatedEv)
            setIsLogRequestPending(true)
            await fetchPlayLogs(updatedEv)
          } catch (error) {
            console.error('Error fetching live event:', error)
            setFetchFails((prev) => ({ ...prev, event: prev.event + 1 }))
            setIsRequestPending(false)
          }
        }, 3000)

        return () => clearInterval(interval)
      } catch (error) {
        console.error('Error in initial live event fetch:', error)
        setFetchFails((prev) => ({ ...prev, event: prev.event + 1 }))
        setIsRequestPending(false)
      }
    }
    return
  }

  const isWithin30Mins = (ev: EventGQL) => {
    //Checking draft status
    const gameStart = ev.startTime
    let now = Date.now() / 1000
    const diff = gameStart - now
    if (diff < 1800 && diff > -1800) {
      //Less than 30 mins till game or within 30 mins of game start
      console.log('Within 30 mins of game')
      return true
    } else {
      return false
    }
  }

  const fetchPlayLogs = async (event: EventGQL) => {
    if (isLogRequestPending) return
    setIsLogRequestPending(true)
    if (gameEvent?.eventStatus === 1 && gameEvent?.id === event.id) {
      await fetchNewPlayLogs(event)
      setIsLogRequestPending(false)
      return
    }

    if (
      playLogs.length > 0 &&
      gameEvent?.eventStatus !== 1 &&
      gameEvent?.id === event.id
    ) {
      setIsLoadingPlayLogs(false)
      setIsLogRequestPending(false)
      setFetchFails((prev) => ({ ...prev, playLogs: 0 }))
      return Promise.resolve()
    } else {
      //Need to get play logs from DB
      await fetchNewPlayLogs(event)
      setIsLogRequestPending(false)
      return
    }
  }

  const fetchNewPlayLogs = async (event: EventGQL) => {
    try {
      const logs = await fetchPlayLogsByEvent(event.externalId)
      if (!logs || logs.length === 0) {
        setIsLoadingPlayLogs(false)
        return []
      }
      const filtered = logs.filter(
        (l) =>
          !l.markerId.includes('-G') &&
          l.eventType !== 'periodend' &&
          l.eventType !== 'start' &&
          l.eventType !== 'periodstart'
      )
      setPlayLogs(filtered)
      setIsLoadingPlayLogs(false)
      setFetchFails((prev) => ({ ...prev, playLogs: 0 }))
      return
    } catch (err) {
      setIsLoadingPlayLogs(false)
      setFetchFails((prev) => ({ ...prev, playLogs: prev.playLogs + 1 }))
      console.log(err)
      return
    }
  }

  return (
    <BroadcastGameContext.Provider
      value={{
        gameEvent,
        playLogs,
        isEventLoading,
        isLoadingPlayLogs,
        fetchPlayLogs,
      }}
    >
      {children}
    </BroadcastGameContext.Provider>
  )
}
