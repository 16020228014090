export const winProbabilitiesFragment = `
  fragment WinProbabilitiesFragment on EventWinProbability {
      markerId
      awayScore
      homeScore
      period
      clockMinutes
      clockSeconds
      secondsPassed
      awayTeamWinProbability
      homeTeamWinProbability
}
  `