import {
  Team,
  TeamGroup,
  TeamPlayerGroups,
  TeamStats,
} from '../../Api/teams/teams.types'
import {
  PackedMap,
  positionStatLists,
  StatGroup,
  StatListType,
  StatMapType,
  StatTypes,
  teamStatFields,
  teamStatHeadersMap,
  wllTeamStatHeadersMap,
} from '../../Constants/stats'
import { Season } from '../../Context/SeasonContext/SeasonContext'
import { ConvertStatValue } from '../formatting'

/**
 * Get stats mapping for player position
 */
export const getMapForTeamGroup = (group: TeamGroup, league?: string): PackedMap => {
  if (!group) return teamStatFields
  const lowercased = group.toLowerCase()

  //Using WLL stats
  if (league === 'wll' && lowercased in wllTeamStatHeadersMap) {
    return wllTeamStatHeadersMap[lowercased]
  }
  if (lowercased in teamStatHeadersMap) {
    return teamStatHeadersMap[lowercased]
  }
  return teamStatFields
}

/**
 * Getting headers
 */
export function getTeamStatList(group: TeamGroup, statType: StatTypes): StatMapType[] {
  const maps = getMapForTeamGroup(group)[statType]
  const result: StatMapType[] = []
  maps.map((key) => {
    const row = teamStatFields[key]
    if (row) {
      result.push({
        name: row[0],
        longName: row[1],
        field: row[2],
      })
    }
  })
  return result
}

/**
 * Get stats mapping for player position
 */
export const getMapForTeamPlayersGroup = (group: TeamPlayerGroups): StatListType => {
  if (!group) return positionStatLists.offense
  const lowercased = group.toLowerCase()
  if (lowercased in positionStatLists) {
    return positionStatLists[lowercased]
  }
  return positionStatLists.offense
}

export function getTeamPlayersStatList(
  position: TeamPlayerGroups,
  statType: StatTypes
): StatMapType[] {
  const maps = getMapForTeamPlayersGroup(position)[statType]
  const result: StatMapType[] = []
  maps.map((key) => {
    const row = teamStatFields[key]
    if (row) {
      result.push({
        name: row[0],
        longName: row[1],
        field: row[2],
      })
    }
  })
  return result
}

/**
 * Stat player groups and the stat to sort by
 */
export const teamPlayersStatGroups: StatGroup[] = [
  { name: 'Attack', sortBy: 'points' },
  { name: 'Midfield', sortBy: 'points' },
  { name: 'Long Stick Midfield', sortBy: 'causeTurnovers' },
  { name: 'Defense', sortBy: 'causeTurnovers' },
  { name: 'Faceoff', sortBy: 'faceoffPct' },
  { name: 'Goalie', sortBy: 'saves' },
  { name: 'Rookies', sortBy: 'points' },
]

/**
 * Stat player groups and the stat to sort by
 */
export const teamsStatsGroups: StatGroup[] = [
  { name: 'Offensive', sortBy: 'scores' },
  { name: 'Defensive', sortBy: 'saa' },
  { name: 'Faceoff', sortBy: 'faceoffPct' },
  { name: 'Goaltending', sortBy: 'savePct' },
  { name: 'Power Play', sortBy: 'powerPlayPct' },
  { name: 'Penalty Kill', sortBy: 'manDownPct' },
]

/**
 * Stat player groups and the stat to sort by
 */
export const teamStatGroups: StatGroup[] = [
  { name: 'Offensive', sortBy: 'scores' },
  { name: 'Defensive', sortBy: 'saa' },
  { name: 'Faceoff', sortBy: 'faceoffPct' },
  { name: 'Goaltending', sortBy: 'savePct' },
  { name: 'Power Play', sortBy: 'powerPlayPct' },
  { name: 'Penalty Kill', sortBy: 'manDownPct' },
  { name: 'Game Stats', sortBy: 'scores' },
  { name: 'Season Stats', sortBy: 'scores' },
  { name: 'All', sortBy: 'scores' },
]

/**
 * Returning list of stats to use, used in getting player leaders or game breakdowns
 */
export const getStatsToUseList = (
  headerType: 'ByPlayerPosition' | 'ByTeamStats' | 'GamePlayers'
): StatGroup[] => {
  switch (headerType) {
    case 'ByPlayerPosition':
      return teamPlayersStatGroups

    case 'ByTeamStats':
      return teamsStatsGroups
    default:
      return []
  }
}

/**
 * Returning formatted stats with checks
 */
export const renderTeamStat = (
  team: Team,
  v: [string, string, string],
  seasonSelect: Season
) => {
  if (seasonSelect.seasonType === 'champseries') {
    if (v[0] === 'PK' || v[0] === 'FO') {
      let merged = mergeStats(team, v, 'champseries')
      return merged
    }
    if (!team.champSeries?.stats || !(v[2] in team.champSeries?.stats)) return '-'
    let stat = team.champSeries?.stats
      ? team.champSeries?.stats[v[2] as keyof TeamStats]
      : 0

    return ConvertStatValue(stat, v[2])
  } else if (seasonSelect.seasonType === 'post') {
    if (v[0] === 'PK' || v[0] === 'FO') {
      let merged = mergeStats(team, v, 'post')
      return merged
    }
    if (!team.postStats || !(v[2] in team.postStats)) return null
    let stat = team.postStats[v[2] as keyof TeamStats] || 0
    return ConvertStatValue(stat, v[2])
  } else {
    if (v[0] === 'PK' || v[0] === 'FO') {
      let merged = mergeStats(team, v, 'regular')
      return merged
    }
    if (!team.stats || !(v[2] in team.stats)) return null
    let stat = team.stats[v[2] as keyof TeamStats] || 0
    return ConvertStatValue(stat, v[2])
  }
}

/**
 * Returning merged stats as one string for tables
 */
export const mergeStats = (team: Team, v: [string, string, string], segment: string) => {
  switch (segment) {
    case 'champseries':
      //PP - Power Plays
      if (!team.champSeries?.stats) return ''
      if (v[0] === 'PP' && team.champSeries?.stats) {
        return `${team.champSeries?.stats?.powerPlayGoals || 0}
        - ${team.champSeries?.stats?.timesManUp || 0}`
      }
      //PK - penalty kills
      if (v[0] === 'PK' && team.champSeries?.stats) {
        return `${
          (team.champSeries?.stats?.timesShortHanded || 0) -
          (team.champSeries?.stats?.powerPlayGoalsAgainst || 0)
        }
        - ${team.champSeries?.stats?.timesShortHanded || 0}`
      }
      //Faceoff string
      if (v[0] === 'FO') {
        return `${team.champSeries?.stats?.faceoffsWon || 0} -
          ${team.champSeries?.stats?.faceoffs || 0}`
      }
      return ''

    case 'post':
      if (!team.postStats) return ''
      //PP - Power Plays
      if (!team.postStats) return ''
      if (v[0] === 'PP' && team.postStats) {
        return `${team.postStats.powerPlayGoals || 0}
                    - ${team.postStats.timesManUp || 0}`
      }
      //PK - penalty kills
      if (v[0] === 'PK' && team.postStats) {
        return `${
          (team.postStats.timesShortHanded || 0) -
          (team.postStats.powerPlayGoalsAgainst || 0)
        }
          - ${team.postStats.timesShortHanded || 0}`
      }
      //Faceoff string
      if (v[0] === 'FO') {
        return `${team.postStats.faceoffsWon || 0} - ${team.postStats.faceoffs || 0}`
      }
      return ''

    case 'regular':
      if (!team.stats) return ''
      //PP - Power Plays
      if (!team.stats) return ''
      if (v[0] === 'PP' && team.stats) {
        return `${team.stats.powerPlayGoals || 0}
                                      - ${team.stats.timesManUp || 0}`
      }
      //PK - penalty kills
      if (v[0] === 'PK' && team.stats) {
        return `${
          (team.stats.timesShortHanded || 0) - (team.stats.powerPlayGoalsAgainst || 0)
        }
          - ${team.stats.timesShortHanded || 0}`
      }
      //Faceoff string
      if (v[0] === 'FO') {
        return `${team.stats.faceoffsWon || 0} - ${team.stats.faceoffs || 0}`
      }
      return ''

    default:
      return ''
  }
}
