export type PackedMap = { [key: string]: [string, string, string] }
export type FilteredResult = StatMapType & { value: number | string | null | undefined }

export type StatListType = {
  season: string[]
  game: string[]
}

export type StatTypes = keyof StatListType
export type StatMapType = {
  name: string
  longName: string
  field: string
}
export type StatGroup = {
  name: string
  sortBy: string
}

export const statDisplayNames: { [key: string]: string } = {
  gamesPlayed: 'Games',
  points: 'Points',
  assists: 'Assists',
  groundBalls: 'Groundballs',
  turnovers: 'Turnovers',
  causedTurnovers: 'Caused Turnovers',
  faceoffsWon: 'Faceoff Wins',
  faceoffPct: 'Faceoff %',
  saves: 'Saves',
  savePct: 'Save %',
  onePointGoals: '1Pt Goals',
  twoPointGoals: '2Pt Goals',
  goals: 'Total Goals',
  scoringPoints: 'Scoring Points',
  touches: 'Touches',
  totalPasses: 'Total Passes',
  shotPct: 'Shot %',
  shots: 'Shots',
  FPTS: 'Szn Fantasy Points',
  'FPTS/G': 'Fantasy Pts/GM',
  pointsPG: 'Points/GM',
  onePointGoalsPG: '1G/GM',
  shotsPG: 'Shots/GM',
  assistsPG: 'Assists/GM',
  savesPG: 'Saves/GM',
  faceoffWinsPG: 'FO Wins/GM',
  causedTurnoversPG: 'Caused Turnovers/GM',
  groundBallsPG: 'Groundballs/GM',
  touchesPG: 'Touches/GM',
  scores: 'Scores',
  saa: 'Scores Against Avg.',
  scoresPG: 'Scores/GM',
  shotsOnGoal: 'Shots on Goal',
  shotsOnGoalPct: 'Shots on Goal %',
  shotsOnGoalPG: 'Shots on Goal/GM',
  twoPointShots: '2Pt Shots',
  twoPointShotPct: '2Pt Shot %',
  twoPointShotsPG: '2Pt Shots/GM',
  twoPointGoalsPG: '2Pt Goals/GM',
  twoPointGoalsAgainst: '2Pt Goals Against',
  timeInPossesion: 'Possession',
  timeInPossesionPct: 'Possession %',
  powerPlayPct: 'Power Play %',
  manDownPct: 'Penalty Kill %',
  clearPct: 'Clear %',
}

// TEAM SPECIFIC STATS CONSTANTS //
// [short name, long name, field name]
export const offensiveTableHeaders: PackedMap = {
  S: ['S', 'Scores', 'scores'],
  '1G': ['1G', '1Pt Goals', 'onePointGoals'],
  '2G': ['2G', '2Pt Goals', 'twoPointGoals'],
  A: ['A', 'Assists', 'assists'],
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
}

// WLL TEAM SPECIFIC STATS CONSTANTS //
export const wllOffensiveTableHeaders: PackedMap = {
  S: ['S', 'Scores', 'scores'],
  '1G': ['1G', '1Pt Goals', 'onePointGoals'],
  A: ['A', 'Assists', 'assists'],
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
}

export const positionStatLists: {
  [key: string]: {
    season: string[]
    game: string[]
  }
} = {
  // FOR POSTIONS: A and M
  offense: {
    season: ['GP', 'P', '1G', '2G', 'A', 'SH%'],
    game: ['P', '1G', '2G', 'A', 'SH%', 'TO'],
  },
  /// SSDM, LSM and D
  defense: {
    season: ['GP', 'CT', 'GB', 'P'],
    game: ['CT', 'GB', 'P'],
  },
  goalie: {
    season: ['GP', 'Sv', 'Sv%'],
    game: ['Sv', 'Sv%', 'GA', '2PTGA'],
  },
  faceoff: {
    season: ['GP', 'FO', 'FO%', 'GB', 'P'],
    game: ['FO', 'FO%', 'GB', 'P', 'A'],
  },
}

export const teamDefenseTableHeaders: PackedMap = {
  SAA: ['SAA', 'Scores Against Avg.', 'saa'],
  CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
  SA: ['SA', 'Scores Against', 'scoresAgainst'],
}

export const teamGoalieTableHeaders: PackedMap = {
  Sv: ['Sv', 'Saves', 'saves'],
  'Sv%': ['Sv %', 'Save %', 'savePct'],
}

export const teamFaceoffTableHeaders: PackedMap = {
  'FO%': ['FO %', 'Faceoff %', 'faceoffPct'],
  FO: ['FO', 'Faceoffs', 'foRecord'],
}

export const teamSpecialTeamsOffensiveTableHeaders: PackedMap = {
  'PP%': ['PP%', 'Power Play Percentage', 'powerPlayPct'],
  PowG: ['PowG', 'Power Play Goals', 'powerPlayGoals'],
  PPSh: ['PPSh', 'Power Play Shots', 'powerPlayShots'],
}
export const teamManDownTableHeaders: PackedMap = {
  'PK%': ['PK%', 'Penalty Kill Percentage', 'manDownPct'],
  PK: ['PK', 'Penalty Kills', 'timesShortHanded'],
  PIM: ['PIM', 'Penalty Minutes', 'pim'],
}

export const fantasyTableHeaders: PackedMap = {
  FPTS: ['FPTS', 'Fantasy Pts', 'seasonTotalPts'],
  'FPTS/G': ['FPTS/G', 'Fantasy Pts/Gm', 'avgPPG'],
}

// Stat mapping All Stats
// [short name, long name, field name]
export const scoringStatFields: PackedMap = {
  '1G': ['1G', '1Pt Goals', 'onePointGoals'],
  '2G': ['2G', '2Pt Goals', 'twoPointGoals'],
  A: ['A', 'Assists', 'assists'],
  TO: ['TO', 'Turnovers', 'turnovers'],
}

export const shootingStatFields: PackedMap = {
  Sh: ['Sh', 'Shots', 'shots'],
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
  '2PtSh': ['2Pt Sh', '2Pt Shots', 'twoPointShots'],
  '2PtSh%': ['2Pt Sh%', '2Pt Shot %', 'twoPointShotPct'],
  SOG: ['SOG', 'Shots On Goal', 'shotsOnGoal'],
  'SOG%': ['SOG%', 'Shots On Goal %', 'shotsOnGoalPct'],
}

export const defensiveStatFields: PackedMap = {
  CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
}

export const faceoffStatFields: PackedMap = {
  'FO%': ['FO %', 'Faceoff %', 'faceoffPct'],
  FOW: ['FOW', 'Faceoffs Won', 'faceoffsWon'],
  FOL: ['FOL', 'Faceoffs Lost', 'faceoffsLost'],
}

export const goaltendingStatFields: PackedMap = {
  Sv: ['Sv', 'Saves', 'saves'],
  'Sv%': ['Sv %', 'Save %', 'savePct'],
  SA: ['SA', 'Scores Against', 'scoresAgainst'],
}

export const specialtyStatFields: PackedMap = {
  Pen: ['Pen', 'Penalties', 'numPenalties'],
  PIM: ['PIM', 'Penalty Minutes', 'pim'],
  PP: ['PP', 'Power Plays', 'timesManUp'],
  'PP%': ['PP%', 'Power Play Percentage', 'powerPlayPct'],
  PowG: ['PowG', 'Power Play Goals', 'powerPlayGoals'],
  PPSh: ['PPSh', 'Power Play Shots', 'powerPlayShots'],
  PK: ['PK', 'Penalty Kills', 'timesShortHanded'],
  'PK%': ['PK%', 'Penalty Kill Percentage', 'manDownPct'],
}

export const teamStatFields: PackedMap = {
  W: ['W', 'Wins', 'teamWins'],
  L: ['L', 'Losses', 'teamLosses'],
  S: ['S', 'Scores', 'scores'],
  ...scoringStatFields,
  ...shootingStatFields,
  ...defensiveStatFields,
  ...faceoffStatFields,
  ...goaltendingStatFields,
  SAA: ['SAA', 'Scores Against Avg.', 'saa'],
  ...specialtyStatFields,
  TCH: ['TCH', 'Touches', 'touches'],
  PAS: ['PAS', 'Total Passes', 'totalPasses'],
  POSS: ['POSS', 'Time Of Possession', 'timeInPossesion'],
  'POSS%': ['POSS%', 'Time Of Possession %', 'timeInPossesionPct'],
}

export const teamSeasonStatFields: PackedMap = {
  S: ['S', 'Scores', 'scores'],
  ...scoringStatFields,
  ...shootingStatFields,
  ...defensiveStatFields,
  ...faceoffStatFields,
  ...goaltendingStatFields,
  SAA: ['SAA', 'Scores Against Avg.', 'saa'],
  ...specialtyStatFields,
  PAS: ['PAS', 'Total Passes', 'totalPasses'],
}

//Team game without wins and losses, saa
export const gameTeamStatFields: PackedMap = {
  S: ['S', 'Scores', 'scores'],
  ...scoringStatFields,
  ...shootingStatFields,
  POSS: ['POSS', 'Time Of Possession', 'timeInPossesion'],
  'POSS%': ['POSS%', 'Time Of Possession %', 'timeInPossesionPct'],
  TCH: ['TCH', 'Touches', 'touches'],
  PAS: ['PAS', 'Total Passes', 'totalPasses'],
  ...defensiveStatFields,
  ...faceoffStatFields,
  ...goaltendingStatFields,
}

export const teamStatHeadersMap = {
  offensive: offensiveTableHeaders,
  defensive: teamDefenseTableHeaders,
  faceoff: teamFaceoffTableHeaders,
  goaltending: teamGoalieTableHeaders,
  'power play': teamSpecialTeamsOffensiveTableHeaders,
  'penalty kill': teamManDownTableHeaders,
  'game stats': gameTeamStatFields,
  'season stats': teamSeasonStatFields,
  all: teamStatFields,
}
export const wllTeamStatHeadersMap = {
  offensive: wllOffensiveTableHeaders,
  defensive: teamDefenseTableHeaders,
  faceoff: teamFaceoffTableHeaders,
  goaltending: teamGoalieTableHeaders,
  'power play': teamSpecialTeamsOffensiveTableHeaders,
  'penalty kill': teamManDownTableHeaders,
  'game stats': gameTeamStatFields,
  'season stats': teamSeasonStatFields,
  all: teamStatFields,
}

//PLAYER SPECIFIC STATS CONSTANTS //

export const offensiveSeasonStatsMap: PackedMap = {
  P: ['P', 'Points', 'points'],
  ...scoringStatFields,
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
  TCH: ['TCH', 'Touches', 'touches'],
  TO: ['TO', 'Turnovers', 'turnovers'],
}

export const defensiveSeasonStatsMap: PackedMap = {
  ...defensiveStatFields,
  P: ['P', 'Points', 'points'],
  A: ['A', 'Assists', 'assists'],
}

export const faceoffSeasonStatsMap: PackedMap = {
  ...faceoffStatFields,
  GB: ['GB', 'Groundballs', 'groundBalls'],
  P: ['P', 'Points', 'points'],
}

export const goalieSeasonStatsMap: PackedMap = {
  ...goaltendingStatFields,
  SAA: ['SAA', 'Scores Against Avg.', 'saa'],
}

export const playerSeasonHeadersPositionMap: { [key: string]: { [key: string]: PackedMap } } = {
  short: {
    A: offensiveSeasonStatsMap,
    M: offensiveSeasonStatsMap,
    SSDM: defensiveSeasonStatsMap,
    LSM: defensiveSeasonStatsMap,
    D: defensiveSeasonStatsMap,
    FO: faceoffSeasonStatsMap,
    G: goalieSeasonStatsMap,
    F: offensiveSeasonStatsMap,
  },
  long: {
    Attack: offensiveSeasonStatsMap,
    Midfield: offensiveSeasonStatsMap,
    'Long Stick Midfield': defensiveSeasonStatsMap,
    'Short Stick Defensive Midfield': defensiveSeasonStatsMap,
    Defense: defensiveSeasonStatsMap,
    Faceoff: faceoffSeasonStatsMap,
    Goalie: goalieSeasonStatsMap,
    Field: offensiveSeasonStatsMap,
  },
}

export const STAT_DESCRIPTIONS = {
  P: 'Points (Goals + Two Point Goals(2) + Assists)',
  TG: 'Total Goals',
  SP: 'Scoring Points (Total points from goals)',
  '1G': 'One Point Goals',
  '2G': 'Two Point Goals',
  A: 'Assists',
  Sh: 'Shots',
  'Sh%': 'Shot Percentage',
  SOG: 'Shots On Goal',
  'SOG%': 'Shots On Goal Percentage',
  '2PtSh': 'Two Point Shots',
  '2PtSh%': 'Two Point Shot Percentage',
  '2SOG': 'Two Point Shots On Goal',
  '2SOG%': 'Two Point Shots On Goal Percentage',
  TO: 'Turnovers',
  CT: 'Caused Turnovers',
  GB: 'Groundballs',
  FO: 'Total faceoffs taken',
  FOW: 'Faceoffs Won',
  FOL: 'Faceoffs Lost',
  'FO%': 'Faceoff Percentage',
  SAA: 'Scores Against Average',
  SA: 'Scores Against',
  Sv: 'Saves',
  'Sv%': 'Save Percentage',
  '2PtGA': 'Two Point Goals Against',
  PEN: 'Number of Penalties',
  PIM: 'Penalty Minutes',
  PowG: 'Power Play Goals',
  PPSH: 'Power Play Shots',
  PPGA: 'Power Play Goals Against',
  SHG: 'Short Handed Goals',
  SHS: 'Short Handed Shots',
  TCH: 'Touches',
  PAS: 'Total Passes',
  'FPTS': 'Fantasy Points',
  'FPTS/G': 'Fantasy Points Per Game',
  'PP%': 'Power Play Percentage',
  'PK%': 'Penalty Kill Percentage',
  'UG': 'Unassisted Goals',
  'AG': 'Assisted Goals',
}
