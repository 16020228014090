import type { Team, TeamStanding } from '../../Api'
import Constants from '../../Context/Constants'
import { PLL_IMAGE_URL } from '../../Config/League/league.config'
import { Season } from '../../Context/SeasonContext/SeasonContext'
import { teamBaseBannerUrls, teamsBasicArray } from '../../Constants/team'
import { statDisplayNames } from '../../Constants/stats'

type TeamColorMap = {
  [key: string]: string
}
type TeamKey = keyof TeamColorMap

export const teamColorMap: TeamColorMap = {
  arc: '#FF5216',
  atl: '#00B1FF',
  can: '#B0B6BB',
  cha: '#FF0E11',
  chr: '#ea5f9a',
  out: '#000000',
  red: '#004B37',
  wat: '#6C20C4',
  whp: '#FFCE00',
  asa: '#000000',
  ash: '#FFCB06',
  ris: '#E0D6C7',
  vet: '#000000',
  wcha: '#00B1FF',
  wgua: '#B0B6BB',
  wchr: '#FFCE00',
  wplm: '#004B37'
}

export const champSeriesTeams: { [key: number]: string[] } = {
  2023: ['arc', 'atl', 'whp', 'chr'],
  2024: ['arc', 'can', 'red', 'wat'],
  2025: ['arc', 'atl', 'whp', 'can'],
}
export function getFullTeamName(teamId: string): string | undefined {
  let tId = teamId.toLowerCase().trim()
  const team = teamsBasicArray.find((td) => td.officialId.toLowerCase() === tId)
  return team?.fullName
}

export function getTeamCode(teamName: string): string | undefined {
  let name = teamName.toLowerCase().trim()
  const team = teamsBasicArray.find((td) => td.fullName.toLowerCase() === name)
  return team?.officialId
}

export function checkRealTeamByCode(teamId: string): string | undefined {
  let tId = teamId.toLowerCase().trim()
  const team = teamsBasicArray.find((td) => td.officialId.toLowerCase() === tId)
  return team?.officialId
}

export const getTeamLogo = (teamId: string, year: number) => {
  const team = Constants.HASHED_TEAMS.find((team) => team.teamCode === teamId)
  if (!team) return
  let yr = year < 2024 ? 2023 : year
  const base = teamBaseBannerUrls[yr]
  return year < 2024
    ? `${base}/${team.teamLogo}@2x.png`
    : `${base}/${team.teamLogo}-${year}.png`
}

export const getGeneralTeamIc = () => {
  return `${PLL_IMAGE_URL}/2021/05/PLL_Icon_Bl.png`
}

export const getTeamColor = (teamId: string) => {
  const tId = teamId.toLowerCase() as TeamKey
  return teamColorMap[tId]
}

export function getTeamRecord(team: Team, season: Season): string | undefined {
  return {
    regular: `${team.teamWins || 0} - ${team.teamLosses || 0} ${
      team.teamTies ? `- ${team.teamTies}` : ''
    }`,
    post: `${team.teamWins || 0} - ${team.teamLosses || 0} ${
      team.teamTies ? `- ${team.teamTies}` : ''
    }`,
    champseries: team.champSeries
      ? `${team.champSeries.teamWins || 0} - ${team.champSeries.teamLosses || 0} ${
          team.champSeries.teamTies ? `- ${team.champSeries.teamTies}` : ''
        }`
      : '',
  }[season.seasonType]
}

export const sortTeamsFunc = (
  list: Team[],
  sortBy: string,
  seasonSegment: string,
  ascDesc: -1 | 1
) => {
  if (sortBy === 'saa') {
    ascDesc = 1
  }
  return list.sort((a, b) => {
    let aStatsToSort =
      seasonSegment === 'post'
        ? a.postStats
        : seasonSegment === 'champseries'
        ? a.champSeries?.stats
        : a.stats
    let bStatsToSort =
      seasonSegment === 'post'
        ? b.postStats
        : seasonSegment === 'champseries'
        ? b.champSeries?.stats
        : b.stats
    const A = aStatsToSort ? aStatsToSort[sortBy] : 0
    const B = bStatsToSort ? bStatsToSort[sortBy] : 0
    const numA = typeof B === 'number' ? B : 0
    const numB = typeof A === 'number' ? A : 0
    if (ascDesc < 0) {
      return numA - numB
    } else {
      return numB - numA
    }
  })
}

export function mapTeamStat(stat: string): string {
  if (stat in statDisplayNames) {
    return statDisplayNames[stat]
  }
  return stat
}

export const getTeamStatCompareList = (league: string) => {
  const lowercasedLeague = league.toLowerCase()
  if (lowercasedLeague === 'wll') {
    return pllTeamStatCompareList.filter(stat => stat !== 'twoPointGoals')
  }
  return pllTeamStatCompareList
}

export const pllTeamStatCompareList = [
  'scores',
  'onePointGoals',
  'twoPointGoals',
  'groundBalls',
  'shots',
  'shotPct',
  'shotsOnGoal',
  'turnovers',
  'causedTurnovers',
  'faceoffsWon',
  'faceoffPct',
  'saves',
  'savePct',
  'touches',
  'totalPasses',
  'timeInPossesion',
  'timeInPossesionPct',
]

export const getLeaderType = (leaderType: string) => {
  return leaderType === 'faceoffPct'
    ? 'Faceoff %'
    : leaderType === 'savePct'
    ? 'Save %'
    : leaderType === 'goals'
    ? 'Total Goals'
    : leaderType === 'onePointGoals'
    ? '1pt Goals'
    : leaderType === 'twoPointGoals'
    ? '2pt Goals'
    : leaderType === 'causedTurnovers'
    ? 'Caused Turnovers'
    : leaderType === 'shotPct'
    ? 'Shot Pct'
    : leaderType
}

export const filterChampSeriesTeams = (teams: Team[], year: number) => {
  let csTeams = champSeriesTeams[year]
  if (!csTeams || csTeams.length < 1) return []
  let teamList = teams.filter((tm) => isChampSeriesTeam(tm, csTeams))
  return teamList
}

export const filterChampSeriesStandings = (teams: TeamStanding[], year: number) => {
  let csTeams = champSeriesTeams[year]
  if (!csTeams || csTeams.length < 1) return []
  let teamList = teams.filter((tm) => isChampSeriesTeam(tm.team, csTeams))
  return teamList
}

export const isChampSeriesTeam = (team: Team, csTeams: string[]) => {
  let tm = csTeams.find((cTm) => cTm === team.officialId.toLowerCase())
  if (!tm) return
  return team
}

export const mapTeamsToCSV = (teams: any[], segment: string) => {
  const cleaned = teams.map((tm) => {
    const stats =
      segment === 'champseries' && tm.champSeries?.stats
        ? tm.champSeries.stats
        : segment === 'post'
        ? tm.postStats
        : tm.stats
    const team = {
      officialId: tm.officialId,
      team: tm?.fullName,
      scores: stats.scores,
      goals: stats.goals,
      onePointGoals: stats.onePointGoals,
      twoPointGoals: stats.twoPointGoals,
      assists: stats.assists,
      shots: stats.shots,
      shotPct: stats.shotPct,
      twoPointShots: stats.twoPointShots,
      twoPointShotPct: stats.twoPointShotPct,
      shotsOnGoal: stats.shotsOnGoal,
      shotsOnGoalPct: stats.shotsOnGoalPct,
      twoPointShotsOnGoal: stats.twoPointShotsOnGoal,
      groundBalls: stats.groundBalls,
      turnovers: stats.turnovers,
      causedTurnovers: stats.causedTurnovers,
      faceoffPct: stats.faceoffPct,
      faceoffsWon: stats.faceoffsWon,
      faceoffsLost: stats.faceoffsLost,
      saves: stats.saves,
      savePct: stats.savePct,
      scoresAgainst: stats.scoresAgainst,
      saa: stats.saa,
      numPenalties: stats.numPenalties,
      pim: stats.pimValue,
      twoPointGoalsAgainst: stats.twoPointGoalsAgainst,
      twoPtGaa: stats.twoPtGaa,
      powerPlays: stats.timesManUp,
      powerPlayPct: stats.powerPlayPct,
      powerPlayGoals: stats.powerPlayGoals,
      powerPlayShots: stats.powerPlayShots,
      timesShortHanded: stats.timesShortHanded,
      manDownPct: stats.manDownPct,
      powerPlayGoalsAgainst: stats.powerPlayGoalsAgainst,
      totalPasses: stats.totalPasses,
    }
    return team
  })
  return cleaned
}
